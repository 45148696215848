import axios from 'axios'
import React, { useEffect, useState } from 'react'

import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'bootstrap-4-react/lib/components/layout';
import { Helmet } from 'react-helmet';



function Termsandconsitions() {


  const [data, setData] = useState()




  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getTermsAndConditiondsData')
      .then(response => { setData(response.data.data)})

  },[])


  return (
    <div>
      
     
        <Helmet>
          <title>Terms and Conditions - Autorent Motorcycle</title>
          <meta name="description" content="Checkout our Terms and Conditions page before renting a motorcycle in Dubai." />
          <meta name="keywords" content="Terms and Conditionds"></meta>
        </Helmet>

     
         

          <Container className='mb-5 mt-5'>
            <Row>
              <Col lg={11} style={{ marginLeft: '1%' }}>
                <h1 style={{ fontSize: "24px" }}>{data && data.title}</h1>
                <p>{data && parse(data.description)}</p>
              </Col>
            </Row>
          </Container>

    </div>
  )
}

export default Termsandconsitions
