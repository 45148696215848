import React, { useState, useEffect } from 'react';
import { Button, Card } from 'bootstrap-4-react/lib/components';
import { Col, Form } from 'react-bootstrap';
import { Container, Row } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import { useContext } from 'react';
import { store } from './Homepage';

function BookNow() {
  const [showModal, setShowModal] = useState(false);
  const [metadata, setMetaData] = useState()
  const [brands, setBrands] = useState([]);
  const [datas, setDatas] = useState();
  const [isLoading, setIsLoading] = useState();
  const [cliked, setCliked] = useState(false)
  const [isLoadings, setIsLoadings] = useState(true)

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getBrands')
      .then(response => { setBrands(response.data.data); setIsLoadings(false) });
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    company_name: '',
    message: '',
    is_select_bike: []
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, start_date: date });
  };

  const handleDateChanges = (date) => {
    setFormData({ ...formData, end_date: date });
  };

  const today = new Date();

  const handleCardClick = (id) => {
    const selectedBikeIds = formData.is_select_bike.slice();

    // Check if the ID is already selected
    const index = selectedBikeIds.indexOf(id);
    if (index !== -1) {
      // If it is, remove it from the selected IDs array
      selectedBikeIds.splice(index, 1);
    } else {
      // If it's not selected, add it to the selected IDs array
      selectedBikeIds.push(id);
    }

    setFormData((prevData) => ({
      ...prevData,
      is_select_bike: selectedBikeIds
    }));
  };

  const handleSubmits = (event) => {
    event.preventDefault();
    axios.post('https://admin.autorentmotorcycle.com/api/postBookings', formData)
      .then(res => {
        setDatas(res.data);
        setIsLoading(false);
        setFormData({
          name: '',
          email: '',
          mobile: '',
          company_name: '',
          message: '',
          is_select_bike: []
        });
      });
  };
  const options = {
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      600: { items: 2 },
      700: { items: 3 },
      1000: { items: 3 }
    }
  };

  return (
    <>
      {isLoading ?
        <Container>
          <Row style={{ height: '650px', padding: '0px' }}>
            <Col style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '10%' }}>
              {/* <Spinner style={{color:'red'}}/>  */}
              <img src='/Asest/Homepage/giphy.gif' width={'100px'} height={'100px'} />
            </Col>
          </Row>
        </Container> :

        <div class="modal fade" id="exampleModal-1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header" style={{ paddingLeft: '48px', paddingRight: '50px' }}>
                <h5  >Enquiry Form</h5>
                <Button variant="secondary" data-dismiss="modal" aria-label="Close">
                  <h5>x</h5>
                </Button>
              </div>
              <div class="modal-body">


                {cliked && isLoading ? <Container>
                  <Row style={{ height: '650px', padding: '0px' }}>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '10%' }}>
                      {/* <Spinner style={{color:'red'}}/>  */}
                      <img src='/Asest/Homepage/giphy.gif' width={'100px'} height={'100px'} />
                    </Col>
                  </Row>
                </Container> :
                  <Container>
                    <Form onSubmit={handleSubmits} autoComplete="off">

                      <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col lg={11}>
                          <Form.Group controlId="name" className='mb-4'   >

                            <Form.Control
                              type="text"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}

                              placeholder='Name'

                            />
                          </Form.Group>

                        </Col>

                        <Col lg={11}>
                          <Form.Group controlId="email" className='mb-4'>
                            <Form.Control
                              type="text"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              required
                              placeholder='Email'
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={11}>
                          <Form.Group controlId="mobile" className='mb-4'>
                            <Form.Control
                              type="text"
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleInputChange}
                              required
                              placeholder='Mobile Number'
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={11}>
                          <Form.Group controlId="company_name" className='mb-4' >
                            <Form.Control
                              type="text"
                              name="company_name"
                              value={formData.company_name}
                              onChange={handleInputChange}
                              placeholder='Company Name'
                              autoComplete="off"

                            />
                          </Form.Group>
                        </Col>
                        {/* <Col lg={6}>

                      <Form.Group controlId="start_date" className='mb-4'>
                        <DatePicker
                          selected={formData.start_date}
                          onChange={handleDateChange}
                          dateFormat="yyyy-MM-dd"
                          name="start_date"
                          className="form-control"
                          minDate={today}
                          placeholderText="Select a start date"


                        />
                      </Form.Group>
                    </Col> */}


                        {/* <Col lg={6}>

                      <Form.Group controlId="end_date" className='mb-4'>
                        <DatePicker
                          selected={formData.end_date}
                          onChange={handleDateChanges}
                          dateFormat="yyyy-MM-dd"
                          name="end_date"
                          className="form-control"
                          minDate={today}
                          placeholderText="Select a end date"


                        />
                      </Form.Group>

                    </Col> */}
                        <Col lg={11}>
                          <Form.Group controlId="message" className='mb-4'>
                            <Form.Control
                              type="text"
                              name="message"
                              placeholder='Message'
                              value={formData.message}
                              onChange={handleInputChange}
                              required
                              style={{ padding: '5px 5px 50px 10px' }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <h5 className='mt-2 text-center'>Select Bike *</h5>
                      <OwlCarousel
                        key={formData.is_select_bike.length} // Key to force re-render on bike selection change
                        className="owl-theme theme-dot"
                        {...options}
                        nav
                      >
                        {brands && brands.map((item, index) => (
                          <Form.Group controlId="is_select_bike" key={item.id}>
                            <Card
                              key={index}
                              style={{
                                padding: '13px',
                                border: formData.is_select_bike.includes(item.id) ? '2px solid green' : 'none'
                              }}
                              id="b-card"
                              onClick={() => handleCardClick(item.id)}
                            >
                              <img src={`${item.large_image_path}/${item.brand_image}`} width={'100%'} height={'auto'} />
                              <h6 className='text-center mt-3' style={{ marginBottom: '0px' }}>{item.brand_title}</h6>
                              <h6 className='text-center'>{item.brand_model}</h6>
                            </Card>
                          </Form.Group>
                        ))}
                      </OwlCarousel>
                      {datas && <h6 style={{ color: 'green' }}>{datas.message}</h6>}

                      <div className='text-center mb-2 mt-2'>
                        <Button className="booking-now" onClick={() => setCliked(true)} >SUBMIT</Button>
                      </div>
                    </Form>
                  </Container>
                }

              </div>

            </div>
          </div>
        </div>
      }
    </>
  )
}

export default BookNow
