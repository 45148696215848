import axios from 'axios'
import React, { useEffect, useState } from 'react'

import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'bootstrap-4-react/lib/components/layout';
import { Helmet } from 'react-helmet';



function Privacycom() {


  const [data, setData] = useState()




  useEffect(() => {
    axios.get('https://admin.autorentmotorcycle.com/api/getPrivacyPolicyData')
      .then(response => { setData(response.data.data)})

  },[])

  console.log('hello')

  return (
    <div>
      
        <Helmet>
          <title>Privacy Policy - Autorent Motorcycle</title>
          <meta name="description" content="Find and book your next motorbike rental anywhere. But before that check and know our privacy policy." />
          <meta name="keywords" content="Terms and Conditionds"></meta>
        </Helmet> 
      
          <Container className='mb-5 mt-5'>
            <Row>
              <Col lg={11} style={{ marginLeft: '1%' }}>
                <h1 style={{ fontSize: "24px" }}>{data && data.title}</h1>
                <p>{data && parse(data.description)}</p>
              </Col>
            </Row>
          </Container>

    </div>
  )
}

export default Privacycom